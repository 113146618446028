var $menuBlock;
var $menuToggle;

$( '.menu-block li:has(ul)' ).doubleTapToGo();


$(function(){
    $menuBlock = $('.menu-block');
    $menuToggle = $('.menu-toggle');

    $menuToggle.on('click', function () {
        $(this).toggleClass("menu-toggle-animate");

        if($('html').hasClass('open-menu')) {
            $('.menu-block').animate(function() {
                left:'-75%'
            }, 1000, function() {
                $('html').removeClass('open-menu');
            });
        }else{
            $('html').addClass('open-menu');
        }

    });

    $(".menu-block .d-item1 > a").append( "<i class='fal fa-angle-down' aria-hidden='true'></i>" );
    $(".menu-block .d-item2 > a").append( "<i class='fal fa-angle-right' aria-hidden='true'></i>" );


    $(".menu-box-bg").click(function () {
        $(".menu-toggle").removeClass("menu-toggle-animate");
    });

    $('.menu-box-bg').on({
        click:function(){
        $('html').removeClass('open-menu');
        }
    });

    resizeMenu();
});

$(window).resize(function() {
    resizeMenu();
});



function resizeMenu() {
    if ($(window).width() < 1024) {
        $('.product__content__text').readmore({
            speed: 300,
            collapsedHeight: 45,
            lessLink: '<a href="#" class="read__more">Lees minder</a>',
            moreLink: '<a href="#" class="read__less">Lees meer</a>'
        });
        $('.product__subject__text').readmore({
            speed: 300,
            collapsedHeight: 45,
            lessLink: '<a href="#" class="read__more">Lees minder</a>',
            moreLink: '<a href="#" class="read__less">Lees meer</a>'
        });

    } else {
        $('.product__content__text').readmore({
            speed: 300,
            collapsedHeight: 500000000
        });
        $('.product__subject__text').readmore({
            speed: 300,
            collapsedHeight: 132,
            lessLink: '<a href="#" class="read__more">Lees minder</a>',
            moreLink: '<a href="#" class="read__less">Lees meer</a>'
        });

    }
    if ($(window).width() < 768) {
        $menuBlock.addClass('menu-block-touch').removeClass('menu-block-click');

        $angleDown = $menuBlock.find('.d-item1');

        // $menuBlock.find('> ul > li').each(function() {
        //     var $level1 = $(this);
        //     if($level1.find('> ul').length > 0) {
        //         // $level1.append( "<i class='fa fa-angle-down fa-three'></i>" );
        //
        //         $level1.find('> ul > li').each(function() {
        //             var $level2 = $(this);
        //             if($level2.find('> ul').length > 0) {
        //             //    $level2.append( "<i class='fa fa-angle-down fa-four'></i>" );
        //             }
        //         });
        //     }
        // });


        $menuBlock.find('.d-item1 .fa-three').click(function () {
            $(this.parentNode).toggleClass('d-open1');
        });

        //  $menuBlock.find('.d-item2 .fa-four').click(function () {
        //      $(this.parentNode).toggleClass('d-open2');
        //  });


        $menuToggle.show();
        $menuBlock.hide();

    } else{
        $menuBlock.removeClass('menu-block-touch').addClass('menu-block-click').show();
        $menuToggle.hide();
    }
}
