$('.form-add-value-date').click(function(){
    if($.trim($('.form-add-value-date .date').val()) == ''){
        $('.form-add-value-date .date').val("dd-mm-jjjj");
    }
});

$('.form-add-value-datetime').click(function(){
    if($.trim($('.form-add-value-datetime .datetime').val()) == ''){
        $('.form-add-value-datetime .datetime').val("dd-mm-jjjj uu:mm");
    }
});


$(".form__label").click(function (e) { 
    $(this).addClass("form__label-selected");
});
$('.form__label input').focus(function () {
    $(this).parent().addClass('form__label-selected');
});
$('.form__label input').blur(function () {
    if ($(this).val()) {
        $(this).parent().addClass('form__label-selected');
    } else {
        $(this).parent().removeClass('form__label-selected');
    }
});
$('.form__label input').each(function () {
    var text_value = $(this).val();
    if (text_value !== '') {
        $(this).parent().addClass('form__label-selected');
    }
});

$('.form__label textarea').focus(function () {
    $(this).parent().addClass('form__label-selected');
});
$('.form__label textarea').blur(function () {
    if ($(this).val()) {
        $(this).parent().addClass('form__label-selected');
    } else {
        $(this).parent().removeClass('form__label-selected');
    }
});
$('.form__label textarea').each(function () {
    var text_value = $(this).val();
    if (text_value !== '') {
        $(this).parent().addClass('form__label-selected');
    }
});

$(function(){
    var now = new Date();
	$('#datepicker input').fdatepicker({
		format: 'dd-mm-yyyy',
		disableDblClickSelection: true,
		leftArrow:'<<',
		rightArrow:'>>',
		language: 'nl',
		closeButton: true,
        startDate: now
	});
    
});

$(function(){
    var now = new Date();  
    $('#datetime input').fdatepicker({
        format: 'dd-mm-yyyy hh:ii',
        disableDblClickSelection: true,
        language: 'nl',
        closeButton: true,
        pickTime: true,
        startDate: now
    });
});

$(function(){
  $('#tim').fdatepicker({
		format: 'hh:ii',
		disableDblClickSelection: true,
		language: 'nl',
		pickTime: true
	});
});