$(function(){
    $('.login__toggle').on('click', function () {
        $(this).toggleClass("login__toggle__animate");

        if($('html').hasClass('login__open__menu')) {
            $('.login__block').animate(function() {
                left:'-75%'
            }, 1000, function() {
                $('html').removeClass('login__open__menu');
            });
        }else{
            $('html').addClass('login__open__menu');
        }
    });

    $(".login__bg").click(function () {
        $(".login__toggle").removeClass("login__toggle__animate");
    });

    $('.login__bg').on({
        click:function(){
            $('html').removeClass('login__open__menu');
        }
    });

    $('.login__block__close').on({
        click:function(){
            $('html').removeClass('login__open__menu');
        }
    });
});
