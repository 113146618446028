$(function(){
    var $bannerWrapper = $('.banner-wrapper');
    $bannerWrapper.on('init', function(slick) {
			$bannerWrapper.fadeIn(50);
			}).slick({
			infinite: true,
			arrows: false,
			dots: true,
            appendDots: '.banner__dots__append',
			autoplay: true,
			speed: 1000,
			fade:true,
			touchThreshold: 100,
			autoplaySpeed: 5000 // 5 seconds
			//fade: true,
			//slidesToShow: 3
        });

    $('.slick-next').click(function() {
        $bannerWrapper.slick('slickNext');
    });
    $('.slick-prev').click(function() {
        $bannerWrapper.slick('slickPrev');
    });
});
